<template>
  <div>
    <div id="data-list-list-view" class="data-list-container mt-2 px-">
      <b-table hover :fields="fields" :items="response" :responsive="true" thead-class="d-none" tbody-tr-class="shadow-sm" table-variant="light">
          <template #cell(expired_at)="item">
            <td>
              <listItem
                :value="item.value"
                :description="$t('Labels.Expire')"
                img="fecha"
                :relativeTime="true"
              />
            </td>
            <td>
              <listItem
                :value="item.value.split(' ')[0]"
                :description="item.value.split(' ')[1]"
                img="fecha"
              />
            </td>
          </template>
          <template #cell(tracker)="item">
            <td>
              <listItem
                :value="item.value.name"
                :description="$tc('Labels.Trackers')"
                img="georeference"
              />
            </td>
          </template>
          <template #cell(uuid)="item">
            <b-button
              variant="primary"
              class="btn-icon rounded-circle btn-option"
              @click="copyURL(item.value)"
              :title="$t('Labels.CopyURL')" v-b-tooltip.hover="{ variant: 'primary', placement: 'lefttop' }"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>
            <b-button
              variant="danger"
              class="btn-icon rounded-circle btn-option"
              @click="stopSharing(item.value)"
              :title="$t('Labels.StopSharing')" v-b-tooltip.hover="{ variant: 'danger', placement: 'bottomright' }"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
      </b-table>
    </div>
    <div v-if="response.length == 0" class="noData">{{$t('Messages.NoData')}}</div>
  </div>
</template>

<script>
import request from '@/libs/request/index'
import {
  BButton,
  BTable
} from 'bootstrap-vue'
import listItem from '@core/spore-components/listItem/listItem.vue'

export default {
  components: {
    BButton,
    BTable,
    listItem
  },
  data () {
    return {
      response: [],
      fields: [
        'expired_at',
        'tracker',
        'uuid'
      ]
    }
  },

  methods: {
    async getResponse () {
      const params = {
        url: 'tracker_public',
        method: 'GET'
      }
      await request(params).then(response => {
        this.response = response.data.data
      }).catch(() => {
        this.response = []
      })
    },
    copyURL (uuid) {
      this.$copyText(`${process.env.VUE_APP_BASE_URL || ''}share/${uuid}`).then(() => {
        this.$swal({
          title: this.$t('Messages.Success'),
          text: this.$t('Messages.URLCopied'),
          timer: 5000,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false
        })
      }, () => {
        this.$swal({
          title: this.$t('Messages.URLError'),
          text: '',
          timer: 5000,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
      })
    },
    stopSharing (uuid) {
      this.$swal({
        title: this.$t('Messages.ConfirmStopSharing'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Messages.Yes'),
        cancelButtonText: this.$t('Messages.Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `tracker_public/${uuid}`,
            method: 'DELETE'
          }
          await request(params).then(response => {
            this.getResponse()
          })
        }
      })
    }
  },

  created () {
    this.getResponse()
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/Shares.scss";
</style>
